import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Kontakta mig`}</h2>
    <p>{`Har du ett intressant förslag eller vill du veta mer?`}<br />{`
Maila på `}<a parentName="p" {...{
        "href": "johanrytterfelt@gmail.com"
      }}>{`johanrytterfelt@gmail.com`}</a>{` eller skicka ett meddelande på `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/johanrytterfelt/"
      }}>{`LinkedIn`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      