import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Om mig`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"Everyone should learn how to program because it teaches you how to think." - Steve Jobs`}</p>
    </blockquote>
    <p>{`Jag har alltid varit intresserad av teknik och datorer. Från att en gång i tiden spela spel på mitt gamla Game Boy till att nu handbygga varje desktop i hushållet. Det är nog ingen överdrift att säga att kalla mig en gamer, och att det har till stor del drivit på mitt datorintresse. I allmänhet är jag helt enkelt typen som gillar att hitta lösningar på olika utmaningar!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      