import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Kunskaper & färdigheter`}</h2>
    <ProjectCard title="React" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Ett ramverk som jag ursprungligen planerade skippa till förmån för Angular, men som jag absolut blev kär i när jag faktiskt provade på det. Från att ha börjat bygga individuella komponenter till att bygga hela SPA-lösningar och hybridappar så har React blivit ett av de JavaScript-ramverk jag värderar högst. Även om det inte automatiskt är ett ramverk som nödvändigtvis värderar konsekvent struktur så ger det en typ av frihet som gör att verkligen allt är möjligt.
    </ProjectCard>
    <ProjectCard title="TypeScript" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Under många år såg jag JavaScript som ett av de mindre ädla språken, inte riktigt värt att tävla med de mer riktiga programmeringsspråken. Det ändrades den dag jag först började meed TypeScript. Strikt typade men dynamiskt hanterade värden är i min åsikt det bästa av två världar: Typsäkerheten och kontrollen för att levera pålitliga och kvalitativa lösningar, men samtidigt flexibiliteten att medvetet hantera saker lite mer vilt för att snabbt kunna bygga proof-of-concepts och workarounds med begränsad budget. Nuförtiden är det (konstigt som det må låta) det språk jag allra helst spenderar min tid i, vare sig det handlar om simpel DOM-manipulation eller faktisk programkod.
    </ProjectCard>
    <ProjectCard title="PHP" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  PHP för mig är som en gammal trovärdig vän. Det har funnits sen jag först började koda och jag förlitar mig på det dagligen än idag. Kanske inte det bästa programmeringsspråket i världen, men ett som jag kan ut och in och som alltid har ett äss i rockärmen för att lösa de mest långsökta problem.
    </ProjectCard>
    <ProjectCard title="Node.js (& NPM)" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Node för mig var en oväntad överraskning. Att använda JavaScript som ett faktiskt programmeringsspråk för mer avancerade saker än att bara manipulera innehåll & funktionalitet på webbsidor kändes ursprungligen helt fel, men i längden ändrade jag helt åsikt. Med tiden har det kommit att bli ett jack-of-all-trades som llöst alla möjliga problem jag stött på. NPM efter ES6 var också början på revolutionen i att göra JavaScript till ett språk som jag faktiskt tog på blodigt allvar.
    </ProjectCard>
    <ProjectCard title="Docker" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Om än det tog mig länge innan jag riktigt började anvädna Docker så är det något jag kommit att värdera högt. Att kunna förkonfigurera och återanvända miljöer gör att jag som utvecklare kan sova mycket bättre på natten med vetskapen om att det inte finns några gömda skillnader som gör att saker fungerar annorlunda mellan utvecklingsmiljöer och produktionsmiljö.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      