/**
 * Styling for the contact section.
 */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { UpDown, UpDownWide, waveAnimation } from '@lekoarts/gatsby-theme-cara/src/styles/animations';
// @ts-ignore
import ContactMDX from '../sections/contact';
import Content from '@lekoarts/gatsby-theme-cara/src/elements/content';
import Divider from '@lekoarts/gatsby-theme-cara/src/elements/divider';
import Footer from './footer';
import Inner from '@lekoarts/gatsby-theme-cara/src/elements/inner';
import React from 'react';
import Svg from './svg';

const Contact = ( { offset, factor = 1 }: { offset: number; factor?: number } ) =>
(
	<div>
		<Divider fill='divider' speed={ 0.2 } offset={ offset } factor={ factor }>
			<div
				sx={
					{
						bottom   : 0,
						position : `absolute`,
						transform: `matrix(1, 0, 0, -1, 0, 0)`,
						width    : `full`,
					}
				}
			>
				<div
					sx={
						{
							height  : `full`,
							path    : { animation: waveAnimation(`20s`) },
							position: `relative`,
							svg     : { width: `100%`, height: `40vh` },
						}
					}
				>
					<svg xmlns='http://www.w3.org/2000/svg' id='contact-wave' viewBox='0 0 800 338.05' preserveAspectRatio='none'>
						<path>
							<animate
								attributeName='d'
								dur='30s'
								repeatCount='indefinite'
								values='M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z'
							/>
						</path>
					</svg>
				</div>
			</div>
		</Divider>
		<Content speed={ 0.4 } offset={ offset } factor={ factor }>
			<Inner>
				<ContactMDX />
			</Inner>
			<Footer />
		</Content>
		<Divider speed={ 0.1 } offset={ offset } factor={ factor }>
			<UpDown>
				<Svg icon='bracketsCurly' hiddenMobile width={ 8 } color='icon_darkest' left='70%' top='20%' />
				<Svg icon='codeMerge' width={ 8 } stroke color='icon_darkest' left='25%' top='5%' />
			</UpDown>
			<UpDownWide>
				<Svg icon='codeMerge' width={ 12 } stroke color='icon_brightest' left='95%' top='50%' />
				<Svg icon='coffee' width={ 6 } color='icon_brightest' left='85%' top='15%' />
				<Svg icon='bracketsCurly' hiddenMobile width={ 8 } color='icon_darkest' left='45%' top='10%' />
			</UpDownWide>
			<Svg icon='coffee' width={ 6 } color='icon_brightest' left='4%' top='20%' />
			<Svg icon='phoneLaptop' width={ 12 } color='icon_darkest' left='70%' top='60%' />
			<Svg icon='terminal' width={ 12 } color='icon_darkest' left='20%' top='30%' />
			<Svg icon='terminal' width={ 8 } stroke color='icon_darkest' left='80%' top='70%' />
		</Divider>
	</div>
);

export default Contact;
